<template>
  <div class="songListCarousel">
    <div class="songListCarousel-title-container">
      <p class="songListCarousel-title-container-title">SONGS</p>
    </div>
    <contentsListing :listContent="songList" :content="content"></contentsListing>
  </div>
</template>
    

<script>
import { eventBus } from "@/eventBus";
export default {
  props: {
    content: {
      type: Object
    }
  },
  data() {
    return {
      songList: null
    };
  },
  methods: {
    getSongList() {
      let payload = {
        objecttype: "CONTENT",
        albumid: this.content.objectid
      };
      eventBus.$emit("get-episode-list", payload);
    },
    displaySongs(payload) {
      this.songList = payload.data;
    }
  },
  created() {
    this.getSongList();
    eventBus.$on("send-episode-list", this.displaySongs);
  },
  components: {
    contentsListing: () =>
      import(
        /* webpackChunkName: "contentsListing" */ "@/components/Templates/contentsListing.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.songListCarousel {
  &-title-container {
    padding: 20px;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: rgba(24, 24, 24, 1);
    &-title {
      opacity: 0.5;
      color: #ffffff;
      font-family: $font-regular;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>